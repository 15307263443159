<template>
    <div class="echars-left1" ref="main"> </div>
</template>
 
<script>
import chartMixins from "./chartMixins";
import "echarts/lib/chart/line";

export default {
    mixins: [chartMixins],
}
</script>
 
<style lang="" scoped></style>
