<template>
    <div class="wrapper">

        <el-row :gutter="20">
            <el-col :span="8">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>线索量</span>
                        <el-button type="success" size="mini">月</el-button>
                    </div>
                    <div class="value-item">
                        <div class="label">本月</div>
                        <div class="value">
                            {{ headerData.clue_month_total }}
                        </div>
                    </div>
                    <div class="value-item">
                        <div class="label">总线索量</div>
                        <div class="value">{{ headerData.clue_total }}</div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>已报名</span>
                        <el-button type="success" size="mini">月</el-button>
                    </div>
                    <div class="value-item">
                        <div class="label">本月</div>
                        <div class="value">
                            {{ headerData.application_month_total }}
                        </div>
                    </div>
                    <div class="value-item">
                        <div class="label">总报名</div>
                        <div class="value">{{ headerData.application_total }}</div>
                    </div>
                </el-card>

            </el-col>
            <el-col :span="8">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>机构数</span>
                        <el-button type="warning" size="mini">总</el-button>
                    </div>
                    <div class="value-item">
                        <div class="label">本月</div>
                        <div class="value">
                            {{ headerData.institution_month_total }}
                        </div>
                    </div>
                    <div class="value-item">
                        <div class="label">机构总数</div>
                        <div class="value">{{ headerData.institution_total }}</div>
                    </div>
                </el-card>
            </el-col>

        </el-row>

        <el-card class="box-card" style="margin-top: 20px;">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="来源名单" name="1"></el-tab-pane>
                <el-tab-pane label="部门名单" name="2"></el-tab-pane>
                <el-tab-pane label="分类名单" name="3"></el-tab-pane>
            </el-tabs>
            <div class="charts">
                <div class="header-box">
                    <div class="title">{{ currentTitle }}</div>
                    <el-select v-model="type" @change="handleMpthChage">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <LineChart :options="chartsData" v-if="activeName == 1" />
                <LineChart :options="chartsData2" v-if="activeName == 2" />
                <LineChart :options="chartsData3" v-if="activeName == 3" />
                <el-radio-group v-model="radio1" v-if="activeName == 1" @change="hdanleVchange">
                    <el-radio-button label="source_all">总录单</el-radio-button>
                    <el-radio-button label="source_eff">有效录单</el-radio-button>
                    <el-radio-button label="source_repeat">重复录单</el-radio-button>
                </el-radio-group>
            </div>

        </el-card>
        <div style="height: 20px;"></div>
        <el-row :gutter="20">
            <el-col :span="8">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>部门占比</span>
                        <el-button type="success" size="mini">月</el-button>
                    </div>
                    <CustomChart :options="barChartData1" />
                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>录量排行</span>
                        <el-button type="success" size="mini">月</el-button>
                    </div>
                    <CustomChart :options="barChartData2" />
                </el-card>

            </el-col>
            <el-col :span="8">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>机构数</span>
                        <el-button type="warning" size="mini">总</el-button>
                    </div>
                    <CustomChart :options="barChartData3" />
                </el-card>
            </el-col>

        </el-row>
    </div>
</template>
 
<script>
import axiosInstance from "../request/request";
import LineChart from '../components/LineChart.vue';
import CustomChart from '../components/CustomChart.vue'
import { cloneDeep } from "lodash";
import {
    echarts8,
    echarts9,
    echarts10,
    echarts11,
    echart12
} from "../echarts/home";
export default {
    components: {
        LineChart,
        CustomChart
    },
    data() {
        return {
            headerData: {},
            activeName: '3',
            type: 1,
            options: [{
                value: 1,
                label: '按天统计'
            }, {
                value: 2,
                label: '按月统计'
            },],
            chartsData: {},
            chartsData2: {},
            chartsData3: {},
            oldData: {},
            radio1: 'source_all',
            barChartData1: {},
            barChartData2: {},
            barChartData3: {},
        }
    },
    mounted() {
        this.getDataBoard()
        this.handleClick()
        this.getBarChartData1()
        this.getBarChartData2()
        this.getBarChartData3()
    },
    computed: {
        currentTitle() {
            const title = { 1: '各平台名单量', 2: '各部门名单量', 3: '各分类名单量' }[this.activeName]
            const dw = this.type === 1 ? '/天' : '/月'
            return title + dw
        }
    },
    methods: {
        getDataBoard() {
            axiosInstance.get('/admin/big/data_board/dataStatistics').then(res => {
                this.headerData = res.data
            })
        },
        getLineData1() {
            let url = '/admin/big/data_board/sourceStatistics?type=' + this.type
            axiosInstance.get(url).then(res => {
                this.oldData = cloneDeep(res.data)
                this.chartsData = echarts8(cloneDeep(res.data), this.transformData(res.data))
            })
        },
        getLineData2() {
            let url = '/admin/big/data_board/departmentData?type=' + this.type
            axiosInstance.get(url).then(res => {
                this.chartsData2 = echarts8(res.data, this.transformData2(res.data))
            })
        },
        getLineData3() {
            let url = '/admin/big/data_board/categoryData?type=' + this.type
            axiosInstance.get(url).then(res => {
                this.chartsData3 = echarts9(res.data, this.transformData2(res.data))
            })
        },
        getBarChartData1() {
            let url = '/admin/big/data_board/departmentStatistics'
            axiosInstance.get(url).then(res => {
                this.barChartData1 = echarts10(res.data.map(i => ({ name: i.name, value: i.clue })))
            })
        },
        getBarChartData2() {
            let url = '/admin/big/data_board/clueStatistics'
            axiosInstance.get(url).then(res => {
                this.barChartData2 = echarts11(res.data)
            })
        },
        getBarChartData3() {
            let url = '/admin/big/data_board/categoryStatistics'
            axiosInstance.get(url).then(res => {
                // console.log(res.data)
                this.barChartData3 = echart12(res.data)
                // this.barChartData3 = echarts11(res.data)
            })
        },
        transformData(data) {
            const res = {}
            Object.values(data).forEach(item => {
                let s = Object.values(item)
                s.pop()
                s.forEach(minItem => {
                    if (!res[minItem.title]) {
                        res[minItem.title] = {
                            name: minItem.title,
                            type: 'line',
                            smooth: true,
                            stack: 'Total',
                            areaStyle: {},
                            emphasis: {
                                focus: 'series'
                            },
                            data: [minItem[this.radio1]]
                        }
                    } else {
                        res[minItem.title].data.push(minItem[this.radio1])
                    }
                })
            })
            return Object.values(res)
        },
        transformData2(data) {
            const res = {}
            Object.values(data).forEach(item => {
                let s = Object.values(item)
                s.pop()
                s.forEach(minItem => {
                    if (!res[minItem.name]) {
                        res[minItem.name] = {
                            name: minItem.name,
                            type: 'line',
                            smooth: true,
                            stack: 'Total',
                            areaStyle: {},
                            emphasis: {
                                focus: 'series'
                            },
                            data: [minItem.num]
                        }
                    } else {
                        res[minItem.name].data.push(minItem.num)
                    }
                })
            })
            // console.log(res)
            return Object.values(res)
        },
        handleClick(e) {
            if (this.activeName == 1) {
                this.getLineData1()
            }
            if (this.activeName == 2) {
                this.getLineData2()
            }
            if (this.activeName == 3) {
                this.getLineData3()
            }
        },
        hdanleVchange() {
            this.chartsData = echarts8(cloneDeep(this.oldData), this.transformData(this.oldData))
        },
        handleMpthChage() {
            if (this.activeName == 1) {
                this.getLineData1()
            }
            if (this.activeName == 2) {
                this.getLineData2()
            }

            if (this.activeName == 3) {
                this.getLineData3()
            }

        }
    }
}
</script>
<style>
.el-card {
    overflow: inherit !important;
}
</style>
<style lang="less" scoped>
.wrapper {
    padding: 12px;
}

.charts {
    position: relative;
}

.header-box {
    z-index: 100000;
    position: absolute;
    width: 96%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.clearfix {
    // text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.value-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.echars-left1 {
    height: 380px;
}
</style>
