<template>
    <div class="echars-center-top" id="echarts4" ref="main"></div>
</template>
 
<script>
import chartMixins from "./chartMixins";
import "echarts/lib/chart/line";

export default {
    mixins: [chartMixins],
}
</script>
 
<style lang="" scoped></style>
