<template>
  <div class="echars">
    <div class="echars-left">
      <LineChart :options="lineOptions1" />
      <LineChart :options="lineOptions2" />
      <LineChart :options="lineOptions3" />
    </div>
    <div class="echars-center">
      <MapChart :options="mapOptions" isMap />
      <div class="echars-center-buttom" id="echarts5">
        <div class="title">录单数据</div>
        <div class="info">
          <div class="left">
            <div class="left-top">
              <div>今日录单<span>{{ clueData.today }}</span>个</div>
              <div>日环比增长{{ clueData.yesterday_chain }}%, 周同比下降{{ clueData.week_chain }}%</div>
            </div>
            <div class="left-buttom">
              <div>有效数据<span>{{ clueData.today_eff }}</span>个</div>
              <div>日环比增长{{ clueData.yesterday_eff_chain }}%, 周同比下降{{ clueData.week_eff_chain }}%</div>
            </div>
          </div>
          <div class="right">
            <div class="theady">
              <div>联系方式</div>
              <div>意向城市</div>
              <div>录入时间</div>
            </div>
            <div class="tbody" v-for="(item, index) in clueData.clue" :key="index">
              <div>{{ item.phone }}</div>
              <div>{{ item.city }}</div>
              <div>{{ item.createtime }}</div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="echars-right">
      <div class="echars-right-top" id="echarts6">
        <div class="text-title">全国各分类机构数排行</div>
        <div class="line" v-for="(item, index) in lineOptions5" :key="index">
          <span>Top{{ index + 1 }}</span>
          <div class="prg">
            <el-progress :text-inside="true" text-color="#ffffff" :format="formatText(item.num, item.name)"
              define-back-color="rgba(0,0,0,0.2)" :stroke-width="15" :percentage="item.num"></el-progress>
          </div>
        </div>
      </div>
      <div class="echars-right-buttom" id="echarts7">
        <div class="text-title">各城市当月录单量排行</div>
        <div class="line" v-for="(item, index) in lineOptions6" :key="index">
          <span>Top{{ index + 1 }}</span>
          <div class="prg">
            <el-progress :text-inside="true" text-color="#ffffff" :format="formatText2(item.num, item.name)"
              define-back-color="rgba(0,0,0,0.2)" :stroke-width="15" :percentage="item.num"></el-progress>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from '../components/LineChart.vue';
import MapChart from '../components/MapChart.vue'
import axiosInstance from "../request/request";
import {
  echarts1,
  echarts2,
  echarts3,
  echarts4,
} from "../echarts/home";

export default {
  components: { LineChart, MapChart },
  data() {
    return {
      lineOptions1: {},
      lineOptions2: {},
      lineOptions3: {},
      mapOptions: {},
      clueData: {},
      lineOptions5: [],
      lineOptions6: [],
    };
  },
  mounted() {
    this.getChartData1()
    this.getChartData2()
    this.getChartData3()
    this.getChartData4()
    this.getChartData5()
    this.getChartData6()
  },
  methods: {
    // 全国各月份分类录单
    getChartData1() {
      axiosInstance.get('/admin/big/big/clue').then(res => {
        this.lineOptions1 = echarts1(res.data)
        this.lineOptions2 = echarts2(res.data)
      })
    },
    // 大屏-全国当月各分类录单 c 
    getChartData2() {
      axiosInstance.get('/admin/big/big/clueNew').then(res => {

        this.lineOptions3 = echarts3(res.data)
      })
    },
    // 校区
    getChartData3() {
      axiosInstance.get('/admin/big/big/school').then(res => {
        const mapData = res.data.map(i => ({ ...i, value: i.num }))
        mapData.push({ name: '南海诸岛', value: 0 })
        this.mapOptions = echarts4(mapData)
      })
    },
    getChartData4() {
      axiosInstance.get('/admin/big/big/clueChain').then(res => {
        this.clueData = res.data
      })
    },
    getChartData5() {
      axiosInstance.get('/admin/big/big/institution').then(res => {
        this.lineOptions5 = Object.values(res.data).sort((a, b) => b.num - a.num)
      })
    },
    getChartData6() {
      axiosInstance.get('/admin/big/big/clueCity').then(res => {
        this.lineOptions6 = Object.values(res.data).sort((a, b) => b.num - a.num)
        console.log(this.lineOptions6)
      })
    },
    formatText(value, name) {
      return function (value) {
        if (name) return `${name}-${value}`
        return value + ''
      }
    },
    formatText2(value, name) {
      return function (value) {
        if (name) return `${name}-${value}`
        return value + ''
      }
    },
  },
};
</script>
<style scoped lang="less">
.echars {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #171d3e;

  .echars-left,
  .echars-right {
    width: 25%;
  }

  .echars-center {
    width: 50%;
    padding: 0 1%;
    box-sizing: border-box;

    .echars-center-top {
      height: 70%;
    }

    .echars-center-buttom {
      height: 28%;
      margin-top: 2%;
      background-color: #2a3253;

      .title {
        color: white;
        text-align: left;
        font-weight: bold;
        padding: 8px;
        font-size: 18px;
      }

      .info {
        padding: 8px;
        padding-top: 0;
        box-sizing: border-box;
        color: white;
        display: flex;
        justify-content: space-between;
        height: calc(100% - 40px);
        box-sizing: border-box;

        .left,
        .right {
          width: 49%;
        }

        .left {
          display: flex;
          flex-direction: column;
          height: 100%;

          .left-top,
          .left-buttom {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-around;
            padding: 10px;
            box-sizing: border-box;
            font-size: 15px;
            background-color: #3e476c;

            div span {
              color: #00bfff;
              font-size: 20px;
            }
          }

          .left-top {
            margin-bottom: 10px;
          }
        }

        .right {
          background-color: #3e476c;

          .theady,
          .tbody {
            height: 40px;
            line-height: 40px;
            display: flex;
            justify-content: space-between;
            font-size: 16px;

            >div {
              width: 33%;
              text-align: center;
            }
          }

          .theady {
            background-color: #8495dc;
          }

          .tbody {
            height: 32px;
            line-height: 32px;

            div {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .echars-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    div {
      height: 32%;
      background-color: #2a3253;
    }
  }

  .echars-right {

    .echars-right-top,
    .echars-right-buttom {
      height: 49%;
      background-color: #2a3253;
      overflow: hidden;
    }

    .echars-right-buttom {
      padding-left: 12px;
      margin-top: 4%;
    }

    .echars-right-top {
      padding-left: 12px;


    }

    .line {
      margin-top: 20px;
      display: flex;
      align-items: center;

      span {
        color: #ffffff;
        font-size: 14px;
        width: 40px;
      }

      .prg {
        flex: 1;
        margin-left: 5px;
      }
    }

    .text-title {
      font-weight: bold;
      font-size: 22px;
      color: #ffffff;
      text-align: left;
      padding-top: 12px;
    }
  }
}
</style>
