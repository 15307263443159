<template>
    <div class="echars-wrap" ref="main"></div>
</template>
 
<script>
import chartMixins from "./chartMixins";
import "echarts/lib/chart/line";

export default {
    mixins: [chartMixins],
}
</script>
 
<style  scoped>
.echars-wrap {
    height: 300px
}
</style>
