import debounce from "lodash/debounce";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import * as echarts from "echarts";
import chinaMap from "../echarts/china";
export default {
    data() {
        return {
            myChart: null
        };
    },
    props: {
        options: {
            type: Object,
            default: () => { }
        },
        isMap: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.init();
        this.initOptionsWatcher();
    },
    beforeDestroy() {
        if (!this.myChart) {
            return;
        }
        window.removeEventListener("resize", this.__resizeHandler);
        this.dispose();
    },
    methods: {
        init() {
            if (this.myChart) {
                return;
            }
            this.myChart = echarts.init(this.$refs.main, "", { renderer: "svg" });
            if (this.isMap) {
                echarts.registerMap("china", chinaMap)
            }
            this.myChart.showLoading();
            this.myChart.setOption(this.options, true);
            this.__resizeHandler = debounce(() => {
                if (this.myChart) {
                    this.myChart.resize();
                }
            }, 100);
            window.addEventListener("resize", this.__resizeHandler);
        },
        delegateMethod(name, ...args) {
            if (!this.myChart) {
                this.init();
            }
            return this.myChart[name](...args);
        },
        dispose() {
            this.delegateMethod("dispose");
            this.myChart = null;
        },
        mergeOptions() {
            if (!this.myChart) {
                this.init();
            } else {
                this.myChart.showLoading();
                this.delegateMethod("setOption", this.options, false, false);
                this.myChart.hideLoading();
            }
        },
        initOptionsWatcher() {
            if (this.__unwatchOptions) {
                this.__unwatchOptions();
                this.__unwatchOptions = null;
            }
            this.__unwatchOptions = this.$watch("options", (val, oldVal) => {
                this.mergeOptions();
            }, { deep: true });
        }
    }
};
