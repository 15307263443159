
export function echarts1(data) {
    const legendData = data[0].clue.map(i => i.name)
    const xAxisData = data.map(i => i.month);
    const yyData = data.map(i => i.clue.find(_ => _.name === '语言').num)
    const dnData = data.map(i => i.clue.find(_ => _.name === '电脑').num)
    const xlData = data.map(i => i.clue.find(_ => _.name === '学历').num)
    const ysData = data.map(i => i.clue.find(_ => _.name === '艺术').num)

    const zyjnData = data.map(i => i.clue.find(_ => _.name === '职业技能').num)
    const zgksData = data.map(i => i.clue.find(_ => _.name === '资格考试').num)
    const lxData = data.map(i => i.clue.find(_ => _.name === '留学').num)
    const k12Data = data.map(i => i.clue.find(_ => _.name === 'K12').num)
    const glpxData = data.map(i => i.clue.find(_ => _.name === '管理培训').num)
    return {
        title: {
            text: '全国各月份分类录单总量',
            textStyle: {
                color: '#ffffff' // 图例字体颜色
            },
            padding: [10, 0, 0, 10], // 标题与图的间距
        },

        tooltip: {
            trigger: 'axis'
        },
        textStyle: {
            color: '#ffffff'
        }, //x,y轴字体颜色
        legend: {
            // data: ['语音', '电脑', '学历', '艺术', "职业技能",'资格考试','留学','K12','管理培训'],
            data: legendData,
            textStyle: {
                color: '#ffffff' // 图例字体颜色
            },
            padding: [40, 0, 0, 0], // 图例与图的间距
        },
        grid: { // 距离上下左右间距
            left: '4%',
            right: '6%',
            top: '30%',
            bottom: '8%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xAxisData,
            axisLine: {
                lineStyle: {
                    color: "#ffffff", // 横轴线条颜色
                },
            },
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                name: '语音',
                type: 'line',
                stack: 'Total',
                // data: [120, 132, 101, 134, 90, 230, 210],
                data: yyData,
                itemStyle: {
                    color: 'yellow' // 设置图例项颜色为蓝色
                }
            },
            {
                name: '电脑',
                type: 'line',
                stack: 'Total',
                data: dnData
            },
            {
                name: '学历',
                type: 'line',
                stack: 'Total',
                data: xlData
            },
            {
                name: '艺术',
                type: 'line',
                stack: 'Total',
                data: ysData
            },
            {
                name: '职业技能',
                type: 'line',
                stack: 'Total',
                data: zyjnData
            },
            {
                name: '资格考试',
                type: 'line',
                stack: 'Total',
                data: zgksData
            },
            {
                name: '留学',
                type: 'line',
                stack: 'Total',
                data: lxData
            },
            {
                name: 'K12',
                type: 'line',
                stack: 'Total',
                data: k12Data
            },
            {
                name: '管理培训',
                type: 'line',
                stack: 'Total',
                data: glpxData
            }
        ]
    }
};


export function echarts2(data) {
    const xAxisData = data.map(i => i.month);
    let seriesData = [];
    data.forEach((item) => {
        const val = item.clue.map(i => i.num).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
        seriesData.push(val)
    })

    return {
        title: {
            text: '全国各月份录单总量',
            textStyle: {
                color: '#ffffff' // 标题字体颜色
            },
            padding: [10, 0, 0, 10], // 图例与图的间距
        },
        textStyle: {
            color: '#ffffff'
        },
        grid: { // 距离上下左右间距
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: xAxisData,
            axisLine: {
                lineStyle: {
                    color: "#ffffff", // 横轴线条颜色
                },
            },
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: seriesData,
                type: 'line'
            }
        ]
    };
}

export function echarts3(data) {
    const xAxisData = data.map(i => i.name);
    const yAxisData = data.map(i => i.num);
    return {
        title: {
            text: '全国当月各分类录单量',
            textStyle: {
                color: '#ffffff' // 标题字体颜色
            },
            padding: [10, 0, 0, 10], // 图例与图的间距
        },

        textStyle: {
            color: '#ffffff'
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        grid: {
            left: '1%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: xAxisData,
                axisTick: {
                    alignWithLabel: true
                },
                axisLine: {
                    lineStyle: {
                        color: "#ffffff", // 横轴线条颜色
                    },
                },
            }
        ],
        yAxis: {
            type: 'value'
        },
        series: [
            {
                type: 'bar',
                data: yAxisData
            }
        ]
    }
}
export function echarts4(mapData) {
    return {
        title: {
            text: '全国校区数',
            textStyle: {
                color: '#ffffff' // 标题字体颜色
            },
            padding: [10, 0, 0, 10], // 图例与图的间距
        },
        tooltip: {

            formatter: function (e) {
                const data = e.data;
                let template = `${data.name}<br/>${data.value}`;
                return template;
            },
            backgroundColor: "rgba(0,0,0,.7)",//提示标签背景颜色
            textStyle: { color: "#fff" }, //提示标签字体颜色
            trigger: "item",
            borderWidth: 1,
            borderColor: "#000"
        },
        geo: {
            map: 'china',
            aspectScale: 0.75, //地图的长宽比
            zoom: 1.1, //图层
            itemStyle: {
                normal: {
                    areaColor: {
                        type: 'radial',
                        x: 0.5,
                        y: 0.5,
                        r: 0.8,
                        colorStops: [
                            {
                                offset: 0,
                                color: '#09132c'
                            },
                            {
                                offset: 1,
                                color: '#274d68'
                            }
                        ],
                        globalCoord: true
                    },
                },
            },

        },
        series: [
            //配置地图相关的数据参数
            {
                type: 'map',
                map: 'china',
                data: mapData,
                label: {
                    normal: {
                        show: true, //显示文字
                        textStyle: {
                            color: '#1DE9B6',

                        }
                    },
                    emphasis: {
                        textStyle: {
                            color: '#ffffff'
                        }
                    }
                },
                zoom: 1.1,

                itemStyle: {
                    normal: {
                        backgroundColor: 'rgb(147,235,248)',
                        borderWidth: 1,
                        areaColor: {
                            type: 'radial',
                            x: 0.5,
                            y: 0.5,
                            r: 0.8,
                            colorStops: [  //渐变
                                {
                                    offset: 0,
                                    color: 'rgb(31,54,150)',
                                },
                                {
                                    offset: 1,
                                    color: 'rgb(89,128,142)'
                                }
                            ],
                            globalCoord: true
                        }
                    },
                    emphasis: {    //高亮效果
                        areaColor: '#00BFFF',
                        borderWidth: 0.1
                    }
                }
            }
        ]
    }
}

export function echarts8(data, data2) {
    const legend = Object.values(data)[0]
    const a = Object.values(legend)
    a.pop()
    const b = a.map(i => i.title || i.name)
    const date = Object.keys(data)
    console.log(b)
    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        legend: {
            data: b,
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '12%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: date
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        dataZoom: [
            {
                type: 'inside',
                start: 0,
                end: 60
            },
            {
                start: 0,
                end: 10
            }
        ],
        series: data2
    };
}

export function echarts9(data, data2) {
    const legend = Object.values(data)[0]
    const a = Object.values(legend)
    a.pop()
    const b = a.map(i => i.title || i.name)
    const date = Object.keys(data)
    // console.log(b)
    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        legend: {
            data: b,
        },
        // toolbox: {
        //     feature: {
        //         saveAsImage: {}
        //     }
        // },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '12%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: date
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        dataZoom: [
            {
                type: 'inside',
                start: 0,
                end: 60
            },
            {
                start: 0,
                end: 10
            }
        ],
        series: data2
    };
}


export function echarts10(data) {
    return {
        legend: {
            top: 'bottom'
        },
        series: [
            {
                name: 'Nightingale Chart',
                type: 'pie',
                radius: [60, 80],
                // center: ['50%', '50%'],
                roseType: 'area',
                itemStyle: {
                    borderRadius: 8
                },
                data,
            }
        ]
    };
}

export function echarts11(data) {
    const xAxisData = data.map(i => i.department + i.cname);
    console.log(xAxisData)
    const yAxisData = data.map(i => i.sum);
    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        grid: {
            left: '1%',
            top: '4',
            right: '2',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: xAxisData,
                axisTick: {
                    alignWithLabel: true
                },
            }
        ],
        yAxis: {
            type: 'value'
        },
        series: [
            {
                type: 'bar',
                data: yAxisData
            }
        ]
    }
}


export function echart12(oldData) {
    const data = Object.values(oldData)
    const xAxisData = data.map(i => i.date);

    const yyData = data.map(i => {
        const data = Object.values(i.data)
        return data.find(_ => _.category === '语言').clue
    })
    const dnData = data.map(i => {
        const data = Object.values(i.data)
        return data.find(_ => _.category === '电脑').clue
    })
    const xlData = data.map(i => {
        const data = Object.values(i.data)
        return data.find(_ => _.category === '学历').clue

    })
    const ysData = data.map(i => {
        const data = Object.values(i.data)
        return data.find(_ => _.category === '艺术').clue

    })

    const zyjnData = data.map(i => {
        const data = Object.values(i.data)
        return data.find(_ => _.category === '职业技能').clue
    })

    const zgksData = data.map(i => {
        const data = Object.values(i.data)
        return data.find(_ => _.category === '资格考试').clue

    })
    const lxData = data.map(i => {
        const data = Object.values(i.data)
        return data.find(_ => _.category === '留学').clue
    })
    const k12Data = data.map(i => {
        const data = Object.values(i.data)
        return data.find(_ => _.category === 'K12').clue

    })
    const glpxData = data.map(i => {
        const data = Object.values(i.data)
        return data.find(_ => _.category === '管理培训').clue

    })
    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {},
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'value'
        },
        yAxis: {
            type: 'category',
            data: xAxisData,
        },
        series: [
            {
                name: '语言',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: yyData
            },
            {
                name: '电脑',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: dnData
            },
            {
                name: '学历',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: xlData
            },
            {
                name: '艺术',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: ysData
            },
            {
                name: '职业技能',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: zyjnData
            },
            {
                name: '资格考试',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: zgksData
            },
            {
                name: '留学',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: lxData
            },
            {
                name: 'K12',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: k12Data
            },
            {
                name: '管理培训',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: glpxData
            },
        ]
    };
}